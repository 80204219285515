import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PageContainer } from '../components/atoms/page-container/page-container';
import { Footer } from '../components/molecules/footer/footer';
import NavBar from '../components/molecules/navbar/navbar';
// @ts-ignore
import WorkoutDuo from '../images/workout-duo.svg';

const AboutUsPage = () => {
  const [sessionCode, setSessionCode] = useState<string>();
  const h1Style = 'font-medium text-2xl md:text-4xl mb-4';
  const subtitleStyle = 'text-lg md:text-xl mb-8';

  useEffect(() => {
    const params = new URLSearchParams(document.location.search.substring(1));
    const code = params.get('code');

    setSessionCode(code);
  }, []);

  return (
    <>
      <main>
        <Helmet>
          <title>Hummingbird - Join Session</title>
          <meta
            name="description"
            content="Download the Hummingbird iOS app to join your workout session and start sharing your personal metrics in real-time."
          />
        </Helmet>

        <NavBar />

        <PageContainer>
          <div className="grid lg:grid-cols-2 gap-4">
            <div>
              <h1 className={h1Style}>Join Your Session</h1>
              <p className={subtitleStyle}>
                Share metrics like heart rate for the optimal workout
              </p>
              <ol className="list-decimal ml-4">
                <li className="mb-6">
                  Download the Hummingbird app for your iPhone and Apple Watch
                  <div className="mt-2">
                    <a
                      href="https://apps.apple.com/us/app/hummingbird-realtime-metrics/id1562006201?itsct=apps_box_badge&amp;itscg=30200"
                      style={{
                        display: 'inline-block',
                        overflow: 'hidden',
                        borderRadius: '13px',
                        width: '150px',
                        height: '50px',
                      }}
                    >
                      <img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1621296000&h=e9b867f5b7f458fd7c86e8a146ae7493"
                        alt="Download on the App Store"
                        style={{
                          borderRadius: '13px',
                          width: '150px',
                          height: '50px',
                        }}
                      />
                    </a>
                  </div>
                </li>
                {sessionCode ? (
                  <li className="mb-6">
                    Enter the session code in the Hummingbird app:{' '}
                    <b>{sessionCode}</b>
                  </li>
                ) : (
                  <li className="mb-6">
                    Ask your trainer for a session code to enter in the
                    Hummingbird app
                  </li>
                )}
                <li className="mb-6">
                  Open the Hummingbird app on your Apple Watch and press "Let's
                  Go" to start sharing your heart rate and other metrics in
                  real-time to optimize your workout.
                </li>
              </ol>
            </div>

            <div className="flex justify-center">
              <img
                className="max-w-ws"
                src={WorkoutDuo}
                alt="Man and woman working out"
              />
            </div>
          </div>
        </PageContainer>
      </main>

      <Footer />
    </>
  );
};

export default AboutUsPage;
